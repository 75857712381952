.navbar {
    background-color: $background;
    border-bottom: 1px solid $box_primary_500;
    .navbar-brand {
        font-size: 1.1rem !important;
        font-weight: 700 !important;
        color: $primary_color !important;
    }
    .nav-link {
        padding-left: 1.5rem !important;
        color: white !important;
        font-weight: 400 !important;
        &:hover {
            color: $primary_color !important;
        }
        button {
            padding: 0 !important;
            padding: 0rem 1rem !important;
        }
    }

    button {
        border: 1px solid $primary_color !important;
        .navbar-toggler-icon {
            font-size: 11px !important;
        }
        transition: 0.4s ease !important;
    }
    // change navbar toggle button image
    .navbar-toggler-icon {
        margin-top: 0 !important;
        margin-bottom: 2px !important;
        background-image: url(../../images/menu.png) !important;
    }
}
.btn-close {
    font-size: 11px !important;
}

// navbar for small device
@media screen and (max-width:991px) {
    .offcanvas-title {
        font-size: 1.1rem !important;
        font-weight: 700 !important;
    }
    .nav-link {
        color: $black_color !important;
        font-weight: 500 !important;
        &:hover {
            color: $primary_color !important;
        }
        padding: 0.55rem 0 !important;
    }
    .hireMe_btn {
        margin-top: 2rem !important;
    }
}
