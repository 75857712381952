.successRate_section {
    .success_box {
        color: #000;
    }
    svg {
        color: $primary_color !important;
        font-size: 40px;
        height: 100%;
    }
}
