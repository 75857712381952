.language_toggle {
  width: 40px;
  height: 40px;
  background-color: $primary_color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid $secondary_color;
  &:hover {
    background-color: $hover_primary_color;
  }
}

.language_sticky {
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 999999;
}
