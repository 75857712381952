.tools_section {
    .tools_box {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
        }
    }
}
