.whyChoose_section {
    svg {
        font-size: 40px;
        color: red;
    }
    // @ work-box
}

.work_box {
    // icon
    .icon {
        height: 50px;
        width: 50px;
        color: $primary_color !important;
    }
    // title
    .title {
        font-size: 17px;
        font-weight: 600;
        padding: 1rem 0 0.6rem 0;
    }
}
