.contact_section {
    .contact_sideBox {
        display: flex;
    }
    .count {
        font-size: 15px;
        padding-top: 0.1rem;
    }
    @media screen {
        @media screen and (max-width: 767px) {
            .contact_sideBox {
                flex-direction: column;
                gap: 0 !important;
            }
        }
    }
    // mail logo in contact form
    svg {
        font-size: 3rem;
        color: $primary_color;
    }
    a {
        color: $primary_color !important;
    }
    .cardBox_section {
        border-radius: 0;
    }
}
