.footer_section {
    background: $background;
    border-top: 1px solid white;
    color: white;
    padding: 1rem 0;
    .footer_box {
        display: flex;
        justify-content: space-between;
        // copyright - text
        .copyright {
            display: flex;
            justify-content: center;
            // copyright - icon
            svg {
                margin-top: 0.2rem;
                margin-right: 0.5rem;
            }
        }
        // footer - item
        .footer_items {
            display: flex;
            .footer_item {
                padding: 0 1rem;
                &:hover {
                    color: $hover_primary_color;
                    cursor: pointer;
                }
            }
        }

        // social icon
        .footer_social {
            padding: 0 !important;
            svg {
                margin: 0 0.3rem;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .footer_box {
            gap: 30px 0;
            align-items: center;
            flex-direction: column;
        }
    }
    @media screen and (max-width: 767px) {
        .footer_box {
            gap: 20px 0;
        }
        // footer - item
        .footer_items {
            display: none !important;
        }
    }
}
