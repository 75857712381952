* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  // * for make non draggable
  // -webkit-user-drag: none;
  // -khtml-user-drag: none;
  // -moz-user-drag: none;
  // -o-user-drag: none;
  // user-select: none;
  // -moz-user-select: none;
  // -webkit-user-drag: none;
  // -webkit-user-select: none;
  // -ms-user-select: none;
}
html,
body {
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px;
  scroll-behavior: smooth;
}

button {
  &:focus {
    box-shadow: none !important;
  }
}
ul {
  list-style: none;
}
a {
  text-decoration: none !important;
  color: black !important;
  &:hover {
    color: $hover_primary_color !important;
  }
}
// scroll bar
::selection {
  color: $primary_color;
  background: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $background;
  &:hover {
    background: $background !important;
  }
}

strong {
  font-weight: 500 !important;
}
