.pageTitle_section {
    text-align: center;
    .title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 19px;
        color: $primary_color;
    }
    .subTitle {
        text-transform: capitalize;
        // color: white;
    }
}
