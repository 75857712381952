.icon_sm {
    font-size: 18px !important;
    &:hover {
        color: $hover_primary_color;
        cursor: pointer;
    }
}
.primary_box {
    background-color: $box_primary_500;
    border-radius: 0.3rem;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab_space {
    text-indent: 5em;
}

// * TEXT
.text_large {
    font-size: 3rem;
    font-weight: 800;
}
.font_wight_1000 {
    font-weight: 1000 !important;
}
.text_medium {
    font-size: 20px;
    font-weight: 500;
    color: $primary_color;
}
.text_title {
    font-size: 17px;
    font-weight: 520;
}
.text_black {
    color: black;
}
.text_primary {
    color: $primary_color;
}
// @ for input error text
.input_suggestion_error {
    color: $error;
    font-size: 11px;
}
