// $primary_color: #2a53e7;
$primary_color: #ff5200;
$hover_primary_color: #dd4905;
$black_color: #000;
// $background: #e4e4e4;
$background: #1a2238;

$secondary_color: #2a53e7;

$box_primary_500: rgb(249, 249, 249);

$error: red;
