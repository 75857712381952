.landing_section {
    background-color: $background;
    height: 100%;

    color: white;
    // padding: 7rem 0 3rem !important;
    display: flex;
    .landing_box {
        // min-height: 80vh;
        padding: 6rem 0 6rem 0;
        // home coding box
        .home_coding_box {
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            // font-family: "Roboto Slab", serif;
            letter-spacing: 0.3px;
            line-height: 25px;
            .primary_box {
                padding: 1.3rem 2rem;
            }
        }
        // welcome box
        .welcome_box {
            @include center;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        @media screen and (max-width:991px) {
            .welcome_box {
                margin-top: 5rem;
            }
            .home_coding_box {
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
        @media screen and (max-width:767px) {
            .home_coding_box {
                display: block;
            }
        }
        // social link
        .social_link {
            display: flex;
            gap: 2rem;
            flex-wrap: wrap;

            .icon {
                display: flex;
                gap: 5px;
                svg {
                    padding-top: 0.1rem;
                    font-size: 18px;
                }
            }
        }
        .background_image {
            // padding: 3rem;
            img {
                width: 100%;
            }
        }

        // hi there
        .triangle_button {
            position: relative;
            border-radius: 0.2rem;
            background-color: $primary_color;
            outline: 1px solid $primary_color;
            color: white;
            .button {
                padding: 0.25rem 1rem;
            }
            .T_button {
                position: absolute;
                // width: 0;
                // height: 0;
                left: 50%;
                right: 50%;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 10px solid $primary_color;
            }
        }
    }
   
}
@media screen and (max-width: 767px) {
    .landing_section {
        padding-bottom: 1rem !important;
    }
    .landing_box {
        padding-top: 0rem;
    }
    .background_image {
        padding: 2rem 0 !important;
    }
}
