.custom_input {
    input,
    textarea {
        &:focus {
            box-shadow: none !important;
            // outline: 1px solid $primary_color;
            border: 1px solid $primary_color;
        }
    }
   
}
