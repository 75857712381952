//! primary --button
@mixin commonPropButton {
    font-size: 15px !important;
    border-radius: 0.2rem;
    padding: 0.5rem 1.5rem 0.4rem 1.5rem !important;
    outline: 1px solid $primary_color !important;
    border: none !important;
    transition: 0.4s ease;
}
.primary_btn {
    @include commonPropButton;
    background: $primary_color;
    color: white;
    &:hover {
        background: $hover_primary_color;
        border: 1px sold transparent !important;
    }
}
// ! primary -- outline button
.primary_outline_btn {
    @include commonPropButton;
    background: transparent;
    color: $primary_color;
    &:hover {
        background: $primary_color !important;
        color: white;
        border: none !important;
    }
}
button {
    svg {
        margin-left: 0.3rem;
    }
}
